import { useContext } from 'react';
import { Button } from 'primereact/button';
import { DataContext } from './DataContext';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'primereact/carousel';
import { useNavigate } from 'react-router';
import ProgressiveImage from 'react-progressive-graceful-image'
function HomeMedia({ setActiveTab }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { media } = useContext(DataContext)
    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const mediaFooter = (
        < div className="flex flex-wrap justify-content-end gap-2" >
            <Button label={t("more")} icon="pi pi-camera" className="p-button-outlined p-button-secondary" onClick={() => {
                navigate('/media');
                setActiveTab("Media");
            }} />
        </div >
    )
    const mediaTemplate = (media) => {
        return (
            <div className="media-item">
                <ProgressiveImage src={media.image} placeholder={media.imageSmall}>
                    {(src) => <img src={src} alt={media.name} className="media-image"
                        onClick={() => window.open(media.link, '_blank')}
                    />}
                </ProgressiveImage>
                <Button link className="media-name" onClick={() => window.open(media.link, '_blank')} label={media.name} />
                <h4 className="media-date" >{media.date}</h4>
            </div>
        );
    };
    return (
        <Card title="Media" footer={mediaFooter}>
            <Carousel
                value={media}
                numVisible={1}
                circular
                autoplayInterval={2500}
                itemTemplate={mediaTemplate}
                responsiveOptions={responsiveOptions}
            />
            <style jsx>{`
                .media-item {
                    text-align: center;
                }

                .media-image {
                    max-width: 100%;
                    height: auto;
                    max-height: 400px; /* Adjust the max-height as needed */
                    margin: 0 auto;
                }

                .media-name {
                    margin-top: 1rem;
                    font-size: 2rem;

                }
            `}</style>
        </Card>
    )
}
export default HomeMedia;
